html,
body {
  font-family: 'Helvetica Neue', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #3c4858;
  font-size: 14px;
  background-color: #eff0f5 !important;
}

/*Reset*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: 'Helvetica Neue', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.3;
  margin: 0;
}

.h5,
h5 {
  font-size: 16px;
  font-weight: 400;
}

p {
  margin: 0;
}

hr {
  border-top: 0.5px solid #d5d7e2;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #6371c7;
}
a:hover,
a:focus {
  color: #6371c7;
  text-decoration: none;
}
a.text-info:hover,
a.text-info:focus {
  color: #00a5bb;
}
a .material-icons {
  vertical-align: middle;
}

a,
button {
  outline: none !important;
}
/*Reset*/

/* margins */
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}

.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

/* paddings */
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-200 {
  padding-top: 200px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-200 {
  padding-bottom: 200px;
}

.p-0 {
  padding: 0;
}

/*Font*/
.text-muted {
  color: #777;
}
.text-primary {
  color: #6371c7;
}
.text-info {
  color: #00bcd4;
}
.text-success {
  color: #4caf50;
}
.text-warning {
  color: #ff9800;
}
.text-danger {
  color: #f44336;
}

.font-sm {
  font-size: 13px;
}
/*Font*/

/*Body*/
.container-dash {
  padding-left: 85px !important;
  margin-top: 40px;
}
/*Body*/

/*Buttons*/
.btn,
.navbar .navbar-nav > li > a.btn {
  width: 210px;
  height: 35px;
  border: none;
  border-radius: 3px;
  position: relative;
  margin: 0 1px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 500;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn,
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus,
.btn:active:hover,
.btn.active:focus,
.btn.active:hover,
.open > .btn.dropdown-toggle,
.open > .btn.dropdown-toggle:focus,
.open > .btn.dropdown-toggle:hover,
.btn.btn-default,
.btn.btn-default:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default.active,
.btn.btn-default:active:focus,
.btn.btn-default:active:hover,
.btn.btn-default.active:focus,
.btn.btn-default.active:hover,
.open > .btn.btn-default.dropdown-toggle,
.open > .btn.btn-default.dropdown-toggle:focus,
.open > .btn.btn-default.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn,
.navbar .navbar-nav > li > a.btn:hover,
.navbar .navbar-nav > li > a.btn:focus,
.navbar .navbar-nav > li > a.btn:active,
.navbar .navbar-nav > li > a.btn.active,
.navbar .navbar-nav > li > a.btn:active:focus,
.navbar .navbar-nav > li > a.btn:active:hover,
.navbar .navbar-nav > li > a.btn.active:focus,
.navbar .navbar-nav > li > a.btn.active:hover,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-default,
.navbar .navbar-nav > li > a.btn.btn-default:hover,
.navbar .navbar-nav > li > a.btn.btn-default:focus,
.navbar .navbar-nav > li > a.btn.btn-default:active,
.navbar .navbar-nav > li > a.btn.btn-default.active,
.navbar .navbar-nav > li > a.btn.btn-default:active:focus,
.navbar .navbar-nav > li > a.btn.btn-default:active:hover,
.navbar .navbar-nav > li > a.btn.btn-default.active:focus,
.navbar .navbar-nav > li > a.btn.btn-default.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-default.dropdown-toggle:hover {
  border: 0.5px solid #3e4990;
  background-color: #6342ff;
}

.btn,
.btn.btn-default,
.navbar .navbar-nav > li > a.btn,
.navbar .navbar-nav > li > a.btn.btn-default {
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

.btn.btn-info:hover,
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info.active,
.btn.btn-info:active:focus,
.btn.btn-info:active:hover,
.btn.btn-info.active:focus,
.btn.btn-info.active:hover,
.open > .btn.btn-info.dropdown-toggle,
.open > .btn.btn-info.dropdown-toggle:focus,
.open > .btn.btn-info.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-info,
.navbar .navbar-nav > li > a.btn.btn-info:hover,
.navbar .navbar-nav > li > a.btn.btn-info:focus,
.navbar .navbar-nav > li > a.btn.btn-info:active,
.navbar .navbar-nav > li > a.btn.btn-info.active,
.navbar .navbar-nav > li > a.btn.btn-info:active:focus,
.navbar .navbar-nav > li > a.btn.btn-info:active:hover,
.navbar .navbar-nav > li > a.btn.btn-info.active:focus,
.navbar .navbar-nav > li > a.btn.btn-info.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-info.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-info.dropdown-toggle:hover,
.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.open > .btn.btn-primary.dropdown-toggle,
.open > .btn.btn-primary.dropdown-toggle:focus,
.open > .btn.btn-primary.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-primary,
.navbar .navbar-nav > li > a.btn.btn-primary:hover,
.navbar .navbar-nav > li > a.btn.btn-primary:focus,
.navbar .navbar-nav > li > a.btn.btn-primary:active,
.navbar .navbar-nav > li > a.btn.btn-primary.active,
.navbar .navbar-nav > li > a.btn.btn-primary:active:focus,
.navbar .navbar-nav > li > a.btn.btn-primary:active:hover,
.navbar .navbar-nav > li > a.btn.btn-primary.active:focus,
.navbar .navbar-nav > li > a.btn.btn-primary.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:hover {
  border: 0.5px solid #3e4990;
  box-shadow: none;
  background-color: #6342ff;
  color: white;
}

.btn.btn-info,
.open > .btn.btn-info.dropdown-toggle,
.navbar .navbar-nav > li > a.btn.btn-info,
.navbar .navbar-nav > li > a.btn.btn-info.active,
.open > .navbar .navbar-nav > li > a.btn.btn-info.dropdown-toggle {
  border: 1px solid #6342ff;
  background-color: white;
  box-shadow: none;
  color: #4f41bd;
}

.btn.btn-success,
.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success.active,
.btn.btn-success:active:focus,
.btn.btn-success:active:hover,
.btn.btn-success.active:focus,
.btn.btn-success.active:hover,
.open > .btn.btn-success.dropdown-toggle,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-success,
.navbar .navbar-nav > li > a.btn.btn-success:hover,
.navbar .navbar-nav > li > a.btn.btn-success:focus,
.navbar .navbar-nav > li > a.btn.btn-success:active,
.navbar .navbar-nav > li > a.btn.btn-success.active,
.navbar .navbar-nav > li > a.btn.btn-success:active:focus,
.navbar .navbar-nav > li > a.btn.btn-success:active:hover,
.navbar .navbar-nav > li > a.btn.btn-success.active:focus,
.navbar .navbar-nav > li > a.btn.btn-success.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-success.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-success.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-success.dropdown-toggle:hover {
  background-color: #4caf50;
  color: #ffffff;
}

.btn.btn-warning,
.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning.active,
.btn.btn-warning:active:focus,
.btn.btn-warning:active:hover,
.btn.btn-warning.active:focus,
.btn.btn-warning.active:hover,
.open > .btn.btn-warning.dropdown-toggle,
.open > .btn.btn-warning.dropdown-toggle:focus,
.open > .btn.btn-warning.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-warning,
.navbar .navbar-nav > li > a.btn.btn-warning:hover,
.navbar .navbar-nav > li > a.btn.btn-warning:focus,
.navbar .navbar-nav > li > a.btn.btn-warning:active,
.navbar .navbar-nav > li > a.btn.btn-warning.active,
.navbar .navbar-nav > li > a.btn.btn-warning:active:focus,
.navbar .navbar-nav > li > a.btn.btn-warning:active:hover,
.navbar .navbar-nav > li > a.btn.btn-warning.active:focus,
.navbar .navbar-nav > li > a.btn.btn-warning.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-warning.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-warning.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-warning.dropdown-toggle:hover {
  background-color: #ff9800;
  color: #ffffff;
}

.btn.btn-danger,
.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger.active,
.btn.btn-danger:active:focus,
.btn.btn-danger:active:hover,
.btn.btn-danger.active:focus,
.btn.btn-danger.active:hover,
.open > .btn.btn-danger.dropdown-toggle,
.open > .btn.btn-danger.dropdown-toggle:focus,
.open > .btn.btn-danger.dropdown-toggle:hover,
.navbar .navbar-nav > li > a.btn.btn-danger,
.navbar .navbar-nav > li > a.btn.btn-danger:hover,
.navbar .navbar-nav > li > a.btn.btn-danger:focus,
.navbar .navbar-nav > li > a.btn.btn-danger:active,
.navbar .navbar-nav > li > a.btn.btn-danger.active,
.navbar .navbar-nav > li > a.btn.btn-danger:active:focus,
.navbar .navbar-nav > li > a.btn.btn-danger:active:hover,
.navbar .navbar-nav > li > a.btn.btn-danger.active:focus,
.navbar .navbar-nav > li > a.btn.btn-danger.active:hover,
.open > .navbar .navbar-nav > li > a.btn.btn-danger.dropdown-toggle,
.open > .navbar .navbar-nav > li > a.btn.btn-danger.dropdown-toggle:focus,
.open > .navbar .navbar-nav > li > a.btn.btn-danger.dropdown-toggle:hover {
  background-color: #f44336;
  color: #ffffff;
}
.btn.btn-simple,
.btn.btn-default.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}
/*.btn sizes*/
.btn.btn-xs,
.btn-group-xs .btn,
.navbar .navbar-nav > li > a.btn.btn-xs,
.btn-group-xs .navbar .navbar-nav > li > a.btn {
  padding: 4px 15px;
  font-size: 10px;
}

.btn.btn-sm,
.btn-group-sm .btn,
.navbar .navbar-nav > li > a.btn.btn-sm,
.btn-group-sm .navbar .navbar-nav > li > a.btn {
  width: 109px;
  height: 30px;
  padding: 3px;
}

.btn.btn-lg,
.btn-group-lg .btn,
.navbar .navbar-nav > li > a.btn.btn-lg,
.btn-group-lg .navbar .navbar-nav > li > a.btn {
  font-size: 14px;
  padding: 14px 36px;
}
/*simple buttons*/
.btn.btn-primary.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-primary.btn-simple {
  background-color: transparent;
  color: #6371c7;
  box-shadow: none;
}

.btn.btn-success.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-success.btn-simple {
  background-color: transparent;
  color: #4caf50;
  box-shadow: none;
}
.btn.btn-danger.btn-simple,
.navbar .navbar-nav > li > a.btn.btn-danger.btn-simple {
  background-color: transparent;
  color: #f44336;
  box-shadow: none;
}
/*.btn hover*/

.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:active,
.btn.btn-default.btn-simple:hover,
.btn.btn-default.btn-simple:focus,
.btn.btn-default.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-simple:active,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple:hover,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple:focus,
.navbar .navbar-nav > li > a.btn.btn-default.btn-simple:active {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}

.tgl + .tgl-btn {
  background-color: #eff0f5;
  border: 1px solid #d5d7e2 !important;
  height: 22px !important;
  border-radius: 11px !important;
}

.tgl-light:checked + .tgl-btn {
  border: 1px solid #6342ff;
}

.tgl:checked + .tgl-btn::after {
  left: 18px;
}
/*Buttons*/

/*Cards*/
.card-default {
  margin-top: 30px;
}

.card {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d5d7e2;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 18px;
  padding-bottom: 15px;
}

.card.card-compact {
  padding-left: 13px;
  padding-right: 19px;
}

.card .img {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .card-content {
  padding: 15px 20px;
  position: relative;
}

.card .card-actions {
  position: absolute;
  z-index: 1;
  top: -50px;
  width: calc(100% - 30px);
  left: 17px;
  right: 17px;
  text-align: center;
}
.card .card-actions .btn {
  padding-left: 12px;
  padding-right: 12px;
}

.card .card-title {
  margin-top: 0;
}

.card-default .card-title,
.card-default .category,
.card-default .card-description {
  text-align: center;
}

.title,
.title a,
.card-title,
.card-title a,
.info-title,
.info-title a,
.footer-brand,
.footer-brand a,
.footer-big h5,
.footer-big h5 a,
.footer-big h4,
.footer-big h4 a,
.media .media-heading,
.media .media-heading a {
  color: #3c4858;
  text-decoration: none;
}

.description,
.card-description,
.footer-big p {
  color: #999999;
}

.card .card-header {
  padding: 15px 20px 0;
  z-index: 3;
}

.card .card-head {
  margin: 20px 20px 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d7e2;
}

.card .card-footer div {
  display: inline-block;
}

.card .card-footer h4 {
  margin: 5px 0;
}
.card .card-footer .btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card .table {
  margin-bottom: 0;
}

.card [data-header-animation='true'] {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.card:hover [data-header-animation='true'] {
  -webkit-transform: translate3d(0, -50px, 0);
  -moz-transform: translate3d(0, -50px, 0);
  -o-transform: translate3d(0, -50px, 0);
  -ms-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
}
.title-header {
  line-height: 41px;
  font-size: 27px;
  margin: 0;
  color: #3c4858;
  font-family: Helvetica Neue, Roboto, Helvetica, Arial, sans-serif;
}
.subtitle-header {
  line-height: 32px;
  font-size: 18px;
  font-weight: 400;
}
/*Cards*/

/*Pills/Tabs*/
.card .nav-pills {
}
.card .tab-content {
}
.nav-pills > li > a {
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  min-width: 100px;
  text-align: center;
  color: #555555;
  transition: all 0.3s;
}
.nav-pills > li > a:hover {
  background-color: rgba(200, 200, 200, 0.2);
}
.nav-pills > li i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background-color: #6371c7;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(99, 113, 199, 0.4);
}
.nav-pills:not(.nav-pills-icons) > li > a {
  border-radius: 3px;
}
.nav-pills.nav-stacked > li + li {
  margin: 10px 0;
}
/*Pills/Tabs*/

/*Forms*/
.form-control,
.form-control:focus {
  background-color: #eff0f5 !important;
  outline: 0 !important;
  border: 1px solid #d5d7e2;
}

.form-control::-moz-placeholder {
  color: #aaaaaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-moz-placeholder {
  color: #aaaaaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control::-webkit-input-placeholder {
  color: #aaaaaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-ms-input-placeholder {
  color: #aaaaaa;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control {
  background-color: #fafafa;
  border: 1px solid #d5d7e2;
  border-radius: 3px;
  color: #2c2c2c;
  line-height: 1;
  height: 34px;
  font-size: 12px;
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  -webkit-box-shadow: none;
  box-shadow: none;
}

select.form-control {
  height: 34px;
}

.form-control:focus {
  border: 1px solid #d5d7e2;
  background-color: #eff0f5;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #2c2c2c;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #f44336;
}

.has-error .quill .ql-snow,
.has-error .form-control,
.has-error .form-control:focus {
  border-color: #f44336;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*
label {
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 10px;
  color: #373C4A;
}
*/

label {
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 5px;
  color: #373c4a;
}

select {
  background: url('/assets/legacy/caret.svg') no-repeat !important;
  background-color: #fafafa !important;
  background-size: 20px !important;
  line-height: 1 !important;
  background-position: right 5px center !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select:focus {
  background-color: #ffffff !important;
}

/*Forms*/

/*Date Picker*/
.react-datepicker {
  border-radius: 3px;
}
.react-datepicker__input-container {
  display: block;
}
.react-datepicker {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #3c4858;
  border: none;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
}
.react-datepicker__header {
  background-color: #6371c7;
  border: 1px solid #6371c7;
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding-top: 8px;
  position: relative;
}
.react-datepicker__month {
  margin: 0;
  padding: 0.4rem;
  border: 1px solid #d5d7e2;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.react-datepicker__current-month {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
.react-datepicker__day-name {
  color: #fff;
}
.react-datepicker__day {
  color: #3c4858;
}
.react-datepicker__navigation--previous {
  top: 17px;
  border-right-color: #fff;
}
.react-datepicker__navigation--next {
  top: 17px;
  border-left-color: #fff;
}
.react-datepicker__day--keyboard-selected {
  border-radius: 3px;
  font-weight: 300;
  background-color: #6371c7;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover {
  border-radius: 3px;
  background-color: #6371c7;
}
.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
  margin-top: -10px;
}
.react-datepicker__tether-element-attached-top.react-datepicker__tether-element {
  margin-top: -5px;
}
.react-datepicker__triangle {
  display: none;
}
/*Date Picker*/

/* checkboxes */
input[type='checkbox'] {
  width: 20px;
  height: 20px;
  background-color: #eff0f5;
  border: 1px solid #d5d7e2;
  border-radius: 3px;
}
/* checkboxes */

/*Tables*/
.table {
  margin-bottom: 0;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 5px 11px;
  vertical-align: middle;
  outline: none !important;
  border-bottom-width: 0px;
  border-top: none;
  text-align: center;
}

.table > thead > tr > th.left-align .table > tbody > tr > td.left-align {
  text-align: left;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  padding: 22px 11px;
  font-size: 16px;
  font-weight: normal;
}
.table > tbody > tr {
  position: relative;
}
.table tr:first-child td {
  padding-top: 18px;
}
.table .td-actions .btn {
  margin: 0px;
  padding: 5px 10px;
}
/*React Tables*/
.react-bs-table table td a {
  width: inherit;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*Tables*/

/*Modals*/
.modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
.modal-content .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}
.modal-content .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}
.modal-content .modal-body + .modal-footer {
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}
.modal-content .modal-footer {
  border-top: none;
}
.modal-header .close {
  margin-top: -2px;
  font-weight: 300;
}
.modal {
  text-align: center;
}
.modal-title {
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    height: 100%;
  }
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}
/*Modals*/

/*Labels*/
.label-container {
  padding-bottom: 10px;
}
.round-star-label {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 50px;
  height: 50px;
  position: relative;
  border: none;
  text-align: center;
  background: #6371c7;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10px;
  box-shadow: 0 14px 26px -12px rgba(99, 113, 199, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(99, 113, 199, 0.2);
}
.round-star-label::before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 50px;
  height: 50px;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  border: none;
  background: #6371c7;
  text-shadow: none;
  -webkit-transform: rotateZ(30deg);
  transform: rotateZ(30deg);
  box-shadow: 0 14px 26px -12px rgba(99, 113, 199, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(99, 113, 199, 0.2);
}
.round-star-label::after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 50px;
  height: 50px;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  border: none;
  background: #6371c7;
  text-shadow: none;
  -webkit-transform: rotateZ(60deg);
  transform: rotateZ(60deg);
  box-shadow: 0 14px 26px -12px rgba(99, 113, 199, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(99, 113, 199, 0.2);
}
.round-star-label .label-text {
  position: absolute;
  color: #ffffff;
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 50px;
  font-size: 18px;
}
/*Labels*/

/*Dropdowns*/
.dropdown-menu {
  border: 1px solid #d5d7e2;
  padding: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
}
.header-nav .dropdown-menu {
  margin-top: 15px;
}
/*Dropdowns*/

/* Tool tip */
h4 > .info-tip {
  margin-top: 6px;
}

h4.h-custom > .info-tip {
  margin-top: 14.5px;
}

label > .info-tip {
  margin-top: 3px;
}

.info-tip-qu,
.info-tip-ex {
  height: 12px;
  width: 12px;
  font-size: 12px;
  background-color: rgba(42, 46, 57, 1);
  position: relative;
}

.info-tip-qu:after {
  content: '?';
  display: block;
  position: absolute !important;
  left: 22%;
  text-rendering: geometricprecision;
}

.info-tip-ex:after {
  content: '!';
  display: block;
  margin-left: 1.1px;
  position: absolute;
  left: 29%;
  text-rendering: geometricprecision;
}
